<template>
  <div :class="`m-3 wrapper wrapper--border wrapper--${bu}`">
    <div class="text-center p-2">
      <h3>Marge controle van: {{ name }}</h3>
      <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Jaar: {{ year_number }} - Week: {{ week_number }}</span
      >
      <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div v-if="!loading && this.bu == 200">
      ><MarginControlTrucksTable
        v-if="margin_control_trucks"
        :table_data="margin_control_trucks"
        :table_style="bu"
      />
      <div v-else class="_text-center program_title">Geen Data</div>
    </div>
    <div v-if="!loading && this.bu == 210">
      <MarginControlVansTable
        v-if="margin_control_vans"
        :table_data="margin_control_vans"
        :table_style="bu"
      />
      <div v-else class="_text-center program_title">Geen Data</div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import MarginControlVansTable from "@/components/MarginControlVansTable.vue";
import MarginControlTrucksTable from "@/components/MarginControlTrucksTable.vue";
import request from "@/functions/request.js";
import { addTime, subtractTime } from "@/functions/date.js";
import { msalInstance } from "vue-msal-browser";

export default {
  props: ["bu"],
  components: { Loading, MarginControlVansTable, MarginControlTrucksTable },
  data: () => ({
    loading: true,
    margin_control_trucks: null,
    margin_control_vans: null,
    name: null,
  }),
  created() {
    this.full_date = this.moment();
    this.name = msalInstance.getAllAccounts()[0].idTokenClaims.name;
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.margin_control_vans = null;
      this.margin_control_trucks = null;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.year_number = this.full_date.year();
      this.week_number = this.full_date.week() - 3;
      if (bu == 200) {
        request(
          `margin-control-trucks/${this.year_number}/${this.week_number}/${this.name}`,
          "GET"
        ).then(({ margin_control_trucks }) => {
          this.margin_control_trucks = margin_control_trucks[this.name];
          this.loading = false;
        });
      } else {
        request(
          `margin-control-vans/${this.year_number}/${this.week_number}/${this.name}`,
          "GET"
        ).then(({ margin_control_vans }) => {
          this.margin_control_vans = margin_control_vans[this.name];
          console.log(margin_control_vans);
          this.loading = false;
        });
      }
    },

    previousWeek() {
      this.loading = true;
      this.margin_control_vans = null;
      this.margin_control_trucks = null;
      this.full_date = subtractTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },

    nextWeek() {
      this.loading = true;
      this.margin_control_vans = null;
      this.margin_control_trucks = null;
      this.full_date = addTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },
  },
};
</script>